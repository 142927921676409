import React from 'react';
import styles from './badgeSkydropx.module.scss';
const BadgeSkydropx = () => {
  return (
    <div className={styles['custom-badge']}>
      <span className={styles['custom-badge-container']}>
        <p>best choose</p>
      </span>
    </div>
  );
};

export default BadgeSkydropx;
